import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import label from 'src/utils/label';
import Answers from './Answers';
import { labels } from 'src/labels';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    image: {
        display: 'inline-block',
        maxWidth: '100%',
        width: 560
      }
}));

const Question = ({ ro, getComments, isChecked, survey, handleSubmit, handleChange, question, varMap, answers, questions, terminate }) => {
    const classes = useStyles();
    let answersFiltered = question.answers;
    let filterConfig = question.filterConfig;
    if (filterConfig) {
        let selectedAnswerIds = answers.filter(item => item.questionId === filterConfig.filterQuestionId).map(s => s.answerId);
        let selectedOtherAnswer = answers.find(item => item.questionId === filterConfig.filterQuestionId && item.comments !== "");
        let selectedQuestion = questions.find(q => q.id === filterConfig.filterQuestionId);
        let selectedAnswerLabels = selectedQuestion.answers.filter(item => selectedAnswerIds.includes(item.id)).map(item => item.label.en);
        answersFiltered = answersFiltered.filter(item => selectedAnswerLabels.includes(item.label.en));
        if(filterConfig.filterOtherQuestionId) {
            let selectedOtherCommentsAnswer = answers.find(item => item.questionId === filterConfig.filterOtherQuestionId && item.comments !== "");
            if(selectedOtherCommentsAnswer) {
                answersFiltered.push({ "id": selectedOtherCommentsAnswer.answerId, "questionId": question.id, "score": 0, "comments": false, "label": { "ar": selectedOtherCommentsAnswer.comments, "en": selectedOtherCommentsAnswer.comments } })
            }
        }
        if (selectedOtherAnswer) {
            answersFiltered.push({ "id": selectedOtherAnswer.answerId, "questionId": question.id, "score": 0, "comments": false, "label": { "ar": selectedOtherAnswer.comments, "en": selectedOtherAnswer.comments } })
        }
    }

    const handleQuestionLabel = (val) => {
        var result = val;
        if (terminate) {
            result = label({ "en": "Sorry, you are not eligible to participate in this survey", "ar": "عذرًا ، لست مؤهلاً للمشاركة في هذا الاستطلاع" })
        }
        if (varMap) {
            varMap.forEach((value, key) => {
                result = result.replaceAll(`{{${key}}}`, value);
            });
        }
        return result;
    };
    let questionEnable = false, matrixEnable = false, labelEnable = true;
    if(survey === 45 && question.id === 1031) {
        questionEnable = true;
    }
    if(survey === 81 && question.id === 2511) {
        questionEnable = true;
    }
    if(survey === 120 && question.id === 5438) {
        questionEnable = true;
    }
    if(survey === 121 && question.id === 5488) {
        questionEnable = true;
    }
    if(survey === 122 && question.id === 5538) {
        questionEnable = true;
    }
    if(survey === 123 && question.id === 5588) {
        questionEnable = true;
    }
    if([124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139].includes(survey) && [5638,5688,5738,5788,5838,5888,5938,5988,6038,6088,6138,6188,6238,6288,6338,6388].includes(question.id)) {
        questionEnable = true;
    }
    const isDesktopOrLaptop = useMediaQuery('(min-width: 1120px)');
    let matrixQuestions = [];
    if((survey === 45 || survey === 60 || survey === 81 || survey === 120 || survey === 121 || survey === 122 || survey === 123) && isDesktopOrLaptop && question.type === 'NPS'){
        matrixEnable = true
        labelEnable = false
        matrixQuestions = (question.id === 5589) ? questions.slice(1, 13) : (question.id === 5601) ? questions.slice(13, 26) : (question.id === 5539) ? questions.slice(1, 13) : (question.id === 5551) ? questions.slice(13, 26) : (question.id === 5489) ? questions.slice(1, 13) : (question.id === 5501) ? questions.slice(13, 26) : (question.id === 5439) ? questions.slice(1, 13) : (question.id === 5451) ? questions.slice(13, 26): (question.id === 2513) ? questions.slice(1, 13) : (question.id === 2526) ? questions.slice(13, 26) : (question.id === 1493) ? questions.slice(11, 20) : (question.id === 1501) ? questions.slice(20, 29) :(question.id === 1030) ? questions.slice(2, 14) : questions.slice(14, 27)
    }
    if(([124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139].includes(survey)) && isDesktopOrLaptop && question.type === 'NPS'){
        matrixEnable = true
        labelEnable = false
        if([5639,5689,5739,5789,5839,5889,5939,5989,6039,6089,6139,6189,6239,6289,6339,6389].includes(question.id)) {
            matrixQuestions = questions.slice(1, 13)
        }
        else if([5651,5701,5751,5801,5851,5901,5951,6001,6051,6101,6151,6201,6251,6301,6351,6401].includes(question.id)){
            matrixQuestions = questions.slice(13, 26)
        }
    }
    if((survey === 78) && isDesktopOrLaptop && question.type === 'NPS'){
        matrixEnable = true
        matrixQuestions = (question.id === 2115) ? questions.slice(5, 15) : questions.slice(15, 27)
        if(question.id === 2126 || question.id === 2115) {
            labelEnable = true
        } else labelEnable = false
    }
    if((survey === 85 && [3157, 3230, 3251].includes(question.id)) && isDesktopOrLaptop && question.type === 'RADIO'){
        matrixEnable = true
        labelEnable = false
        if(question.id === 3157) {
            matrixQuestions = questions.slice(20, 31)
        }
        else if(question.id === 3230){
            matrixQuestions = questions.slice(93, 113)
        }
        else if(question.id === 3251){
            matrixQuestions = questions.slice(114, 134)
        }
    }
    if((survey === 86 && question.id === 3333) && isDesktopOrLaptop && question.type === 'RADIO'){
        matrixEnable = true
        labelEnable = false
        matrixQuestions = questions.slice(5, 13)
    }
    if((survey === 94 && [4611, 4639, 4661, 4705, 4762, 4776, 4792, 4816].includes(question.id)) && isDesktopOrLaptop && (question.type === 'RADIO' || question.type === 'CHECKBOX')){
        matrixEnable = true
        labelEnable = false
        if(question.id === 4611) {
            matrixQuestions = questions.slice(9, 15)
        }
        else if(question.id === 4639){
            matrixQuestions = questions.slice(37, 55)
        }
        else if(question.id === 4661){
            matrixQuestions = questions.slice(59, 102)
        }
        else if(question.id === 4705){
            matrixQuestions = questions.slice(103, 146)
        }
        else if(question.id === 4762){
            matrixQuestions = questions.slice(160, 173)
        }
        else if(question.id === 4776){
            matrixQuestions = questions.slice(174, 187)
        }
        else if(question.id === 4792){
            matrixQuestions = questions.slice(190, 211)
        }
        else if(question.id === 4816){
            matrixQuestions = questions.slice(214, 226)
        }
    }
    if((survey === 114 && [5289,5304,5309].includes(question.id)) && isDesktopOrLaptop && (question.type === 'RADIO')){
        matrixEnable = true
        labelEnable = false
        if(question.id === 5289) {
            matrixQuestions = questions.slice(77, 89)
        }else if(question.id === 5304) {
            matrixQuestions = questions.slice(92, 96)
        }else if(question.id === 5309) {
            matrixQuestions = questions.slice(97, 113)
        }
    }
    if((survey === 117 && [5345,5354,5362].includes(question.id)) && isDesktopOrLaptop && (question.type === 'RADIO')){
        matrixEnable = true
        labelEnable = false
        if(question.id === 5345) {
            matrixQuestions = questions.slice(10, 19)
        }else if(question.id === 5354) {
            matrixQuestions = questions.slice(19, 27)
        }else if(question.id === 5362) {
            matrixQuestions = questions.slice(27, 35)
        }
    }
    if((survey === 118 && [5382,5391,5399].includes(question.id)) && isDesktopOrLaptop && (question.type === 'RADIO')){
        matrixEnable = true
        labelEnable = false
        if(question.id === 5382) {
            matrixQuestions = questions.slice(10, 19)
        }else if(question.id === 5391) {
            matrixQuestions = questions.slice(19, 27)
        }else if(question.id === 5399) {
            matrixQuestions = questions.slice(27, 35)
        }
    }
    if((survey === 119 && [5416,5424].includes(question.id)) && isDesktopOrLaptop && (question.type === 'RADIO')){
        matrixEnable = true
        labelEnable = false
        if(question.id === 5416) {
            matrixQuestions = questions.slice(7, 15)
        }else if(question.id === 5424) {
            matrixQuestions = questions.slice(15, 23)
        }
    }
    if((survey === 140 && [6443].includes(question.id)) && isDesktopOrLaptop && (question.type === 'RADIO')){
        matrixEnable = true
        labelEnable = false
        if(question.id === 6443) {
            matrixQuestions = questions.slice(5, 15)
        }
    }
    if((survey === 115 && [5123,5129,5168,5184,5190,5109].includes(question.id)) && isDesktopOrLaptop && (question.type === 'RADIO')){
        matrixEnable = true
        labelEnable = false
        if(question.id === 5109) {
            matrixQuestions = questions.slice(23, 35)
        }else if(question.id === 5123) {
            matrixQuestions = questions.slice(36, 41)
        }else if(question.id === 5129) {
            matrixQuestions = questions.slice(41, 49)
        }else if(question.id === 5168) {
            matrixQuestions = questions.slice(79, 91)
        }else if(question.id === 5184) {
            matrixQuestions = questions.slice(94, 98)
        }else if(question.id === 5190) {
            matrixQuestions = questions.slice(99, 115)
        }
    }
    if((survey === 109 && [5005, 5012].includes(question.id)) && isDesktopOrLaptop && (question.type === 'RADIO')){
        matrixEnable = true
        labelEnable = false
        if(question.id === 5005) {
            matrixQuestions = questions.slice(7, 14)
        }else if(question.id === 5012) {
            matrixQuestions = questions.slice(14, 23)
        }
    }
    if((survey === 97 && question.id === 4909) && isDesktopOrLaptop && question.type === 'RADIO'){
        matrixEnable = true
        labelEnable = false
        if(question.id === 4909) {
            matrixQuestions = questions.slice(6, 15)
        }
    }

    if((survey === 98 && question.id === 4925) && isDesktopOrLaptop && question.type === 'RADIO'){
        matrixEnable = true
        labelEnable = false
        if(question.id === 4925) {
            matrixQuestions = questions.slice(5, 14)
        }
    }

    if(survey === 45 && question.id === 1070){
        matrixEnable = true
        labelEnable = true
        matrixQuestions = questions
    }
    return (
        <Box p={2}>
            {matrixEnable && labelEnable && <Typography variant="subtitle2" style={{whiteSpace: 'pre-line'}}>
                {(survey === 78) ? handleQuestionLabel(label(question.label).split(':')[0]) : handleQuestionLabel(label(question.label))}
            </Typography>}
            {matrixEnable && <Box>
                {question.answers
                    && question.answers.length > 0 && <Box pt={3}>
                    <Answers ro={ro} survey={survey} handleSubmit={handleSubmit} questions={matrixQuestions} questionsType={question.type} isChecked={isChecked} handleChange={handleChange} questionId={question.id} type={'MATRIX'} answers={answersFiltered} />
                </Box>}
            </Box>}
            {!matrixEnable && <Typography variant="subtitle2" style={{whiteSpace: 'pre-line'}}>
                {handleQuestionLabel(label(question.label))}
            </Typography>}
            {questionEnable && 
                <Box pt={3}>
                    <img
                    alt="NPS Label"
                    className={classes.image}
                    src={label(labels.get("survey_nps_image"))}
                    />
            </Box>}
            {!matrixEnable && question.answers
                && question.answers.length > 0
                && <Box pt={3}><Answers ro={ro} survey={survey} handleSubmit={handleSubmit} isComments={question.comments} getComments={getComments} isChecked={isChecked} handleChange={handleChange} type={question.type} questionId={question.id} answers={answersFiltered} /></Box>}
        </Box>
    );
}
export default Question;