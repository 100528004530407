import React from 'react';
import Radios from './Radios';
import Checkboxes from './CheckBoxes';
import NPS from './NPS';
import Matrix from './Matrix';
import Smileys from './Smileys';
import Box from '@material-ui/core/Box';


const Answers = ({ ro, survey, handleSubmit, isComments, getComments, questions, questionsType, isChecked, handleChange, type, questionId, answers }) => {

    const renderAnswers = () => {
        switch (type) {
            case 'MATRIX':
                return <Matrix ro={ro} getComments={getComments} survey={survey} handleSubmit={handleSubmit} isComments={isComments} questionsType={questionsType} questionId={questionId} matrixQuestions={questions} isChecked={isChecked} handleChange={handleChange} key={type} answers={answers} />;
            case 'RADIO':
                return <Radios ro={ro} isComments={isComments} survey={survey} getComments={getComments} isChecked={isChecked} handleChange={handleChange} key={type} questionId={questionId} answers={answers} />;
            case 'CHECKBOX':
                return <Checkboxes ro={ro} isComments={isComments} survey={survey} getComments={getComments} isChecked={isChecked} handleChange={handleChange} key={type} questionId={questionId} answers={answers} />;
            case 'NPS':
                return <NPS ro={ro} survey={survey} isChecked={isChecked} handleChange={handleChange} key={type} questionId={questionId} answers={answers} />;
            case 'SMILEY':
                return <Smileys ro={ro} isChecked={isChecked} handleChange={handleChange} key={type} questionId={questionId} answers={answers} />;
            default:
                return null;
        }
    }

    return (
        <Box>{renderAnswers()}</Box>
    );
}
export default Answers;