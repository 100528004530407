import React, { useEffect, useState, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import label from 'src/utils/label';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { green } from "@material-ui/core/colors";
import theme from 'src/theme';
import { useMediaQuery } from "@material-ui/core";

const TextArea = ({ ro, getComments, handleChange, survey, questionId, answer }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
            },
            '& label.Mui-focused': {
                color: survey === 118 ? 'blue' : survey === 119 ? '#ffaa01' : green[600],
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: survey === 118 ? 'blue' : survey === 119 ? '#ffaa01' : green[400],
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: survey === 118 ? 'blue' : survey === 119 ? '#ffaa01' : green[400],
                },
                '&:hover fieldset': {
                    borderColor: survey === 118 ? 'blue' : survey === 119 ? '#ffaa01' : green[400],
                },
                '&.Mui-focused fieldset': {
                    borderColor: survey === 118 ? 'blue' : survey === 119 ? '#ffaa01' : green[600],
                },
            },
        }
    }));
    const classes = useStyles();
    const [value, setValue] = useState('');
    const desktop = useMediaQuery(theme.breakpoints.down('lg'));
    const handleOnChange = useCallback((e, questionId) => {
        let questionIds = [1589, 1590, 1689, 1855], numberQuestionFound = false;
        numberQuestionFound = questionIds.some(id => id === questionId);
        let maxLen = ( questionId === 1589 || questionId === 1689 || questionId === 1855) ? 12 : 5;
        if ((numberQuestionFound) && (e.target.value.length > maxLen || !/(^$|^\d*$)/.test(e.target.value))) {
            return;
        }
        else {
            setValue(e.target.value);
            handleChange(e);
        }
    }, [handleChange]);
    useEffect(() => {
        setValue(getComments(questionId, answer.id));
    }, [getComments, questionId, answer]);
    return (
        <FormControlLabel
            className={classes.root}
            control={<TextField
                fullWidth
                style={{width: ((desktop && questionId === 3342) || questionId === 4888 || questionId === 5021 || questionId === 5022 || questionId === 6453 || questionId === 6454) ? '-webkit-fill-available' : (questionId === 1606 || questionId === 1700) ? '275px' : '100% !important' }}
                label={label(answer.label)}
                id={questionId + '_' + answer.id}
                variant="outlined"
                multiline 
                rows={(questionId === 1606 || questionId === 1700 || questionId === 6453 || questionId === 6454 || questionId === 7020 || questionId === 7021) ? 4 : 1}
            />}
            value={value}
            onChange={(e) => handleOnChange(e, questionId)}
            disabled={JSON.parse(ro)}
        />
    )
}


export default TextArea;