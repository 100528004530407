import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { makeStyles } from '@material-ui/core/styles';
import TextArea from './TextArea';
import Box from "@material-ui/core/Box";
import label from 'src/utils/label';


const useStyles = makeStyles({
    group: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
    }
});

const Radios = ({ ro, isComments, survey, getComments, isChecked, handleChange, questionId, answers }) => {
    const classes = useStyles();
    const GreenRadio = withStyles({
        root: {
            color: survey === 118 ? 'blue' : survey === 119 ? '#ffaa01' : green[400],
            "&$checked": {
                color: survey === 118 ? 'blue' : survey === 119 ? '#ffaa01' : green[600]
            },
        },
        checked: {}
    })((props) => <Radio color= "default" {...props} />);
    const answersList = answers.map((answer, index) => {
        if (isComments) {
            if (index < (answers.length - 1)) {
                return (
                    <FormControlLabel
                        style={{ paddingTop: '7px' }}
                        key={answer.id}
                        value={questionId + '_' + answer.id}
                        control={<GreenRadio size="small" />}
                        label={label(answer.label)}
                        labelPlacement="end"
                        onChange={handleChange}
                        checked={isChecked(questionId, answer.id)}
                    />
                )
            }
            if (index === (answers.length - 1)) {
                return <TextArea ro={ro} survey={survey} getComments={getComments} key={answer.id} handleChange={handleChange} questionId={questionId} answer={answer} />;
            }
        } else {
            return (
                <FormControlLabel
                    style={{ paddingTop: '7px' }}
                    key={answer.id}
                    value={questionId + '_' + answer.id}
                    control={<GreenRadio size="small" />}
                    label={label(answer.label)}
                    labelPlacement="end"
                    onChange={handleChange}
                    checked={isChecked(questionId, answer.id)}
                />
            )
        }
        return null;
    });

    return (
        <Box display="flex" alignItems="left" justifyContent="left" flexDirection="column">
            <FormControl disabled={JSON.parse(ro)} component="fieldset">
                <RadioGroup className={classes.group} aria-label={'q' + questionId} name={'q' + questionId} >
                    {answersList}
                </RadioGroup>
            </FormControl>
        </Box>
    );
}

export default Radios;